import { default as appliedFiltersInit, attachCheckboxFilterChange } from "./applied-filters.js"
import { default as folksSelectorInit } from "./folks-selector.js"
import { default as searchBlockPopupButtonsInit } from "./search-block-popup-buttons.js"
import { attachEvents as accommodationOnlyAttachEvents } from "./accommodation-only.js"
import { reInitAfterSearch as airportPickerReInitAfterSearch } from "./airport-picker.js"
import { loader } from "./popups.js";
import { updateSearchBarOptions as searchBarUpdateSearchBarOptions } from "./search-bar.js";
import { removeParam as urlManagementRemoveParam, updateParam as urlManagementUpdateParam } from "./url-management.js"
import { default as formsInit } from "./forms.js";
import { default as refineInit } from "./refine.js";
import { default as helpPinInit } from "./help-pin.js";
import { default as toggleBlockInit } from "./toggle-block.js";
import { currentTabSelection } from "./tabs-simple.js";

// ajax request instance
let request = null;

// timeout instance
let timeout = null;

let firstRun = true;

// time we should wait after no interactions with the dynamic form before submitting any request (ms)
const waitTime = 500;


const mobileSearchFiltersHideBreakpoint = 787;

// store any states
const states = {
	filterLimits: {
		data: [],

		store: function() {
			states.filterLimits.data = [];
			elements.form.find('[data-limit]').each(function() {

				var target = $(this),
					wrapper = target.parent(),
					toggle = wrapper.find('[data-limit-toggle]');

				states.filterLimits.data.push({
					wrapper: wrapper,
					open: toggle.length && target.attr('data-limit-open') === 'true' ? true : false
				});
			});
		},

		restore: function() {

			// initialise new refine elements (otherwise known as limit)
			refineInit();

			$.each(states.filterLimits.data, function(key, value) {
				var more = value.wrapper.find('[data-limit-toggle]');
				if (value.open) more.trigger('click');
			});
		}
	},

	tabs: {

		index: null,
		store: function() {
			states.tabs.index = elements.form.find('.js-tabs-links-link.active').parent().index();
		},

		parse: function(model) {

			if (states.tabs.index) {
				model.VillasAndApartmentsTabActiveString = '';
				model.HotelTabActiveString = 'active';
			}
			else {
				model.VillasAndApartmentsTabActiveString = 'active';
				model.HotelTabActiveString = '';
			}

			return model;
		}
	}
}

// facilitate the caching and re-caching of injected elements
const elements = {

	form: $('.js-dynamic-search'),

	checks: null,
	radios: null,
	clear: null,
	sort: null,
	prev: null,
	next: null,
	page: null,
	folksSelectorWrapper: null,
	where: null,
	when: null,
	duration: null,

	cache: function() {
		elements.checks = elements.form.find('input[type="checkbox"]').not('.js-dynamic-search-exclude');
		elements.radios = elements.form.find('input[type="radio"]');
		elements.clear = elements.form.find('.js-dynamic-search-clear');
		elements.sort = elements.form.find('.js-dynamic-search-sort');
		elements.prev = elements.form.find('.js-dynamic-search-prev');
		elements.next = elements.form.find('.js-dynamic-search-next');
		elements.page = elements.form.find('.js-dynamic-search-page');
		elements.folksSelectorWrapper = elements.form.find('.js-tabs-tab.active .js-folks-selector-wrapper');
		elements.where = elements.form.find('.js-tabs-tab.active .js-dynamic-search-where');
		elements.when = elements.form.find('.js-tabs-tab.active .js-dynamic-search-when');
		elements.duration = elements.form.find('.js-tabs-tab.active .js-dynamic-search-duration');
	}
}

// facilitate the caching and setting of mustache templates / check whether all elements requried for dynamic search are present
const templates = {
	elements: {
		block: $('#searchBlock'),
		folk: $('#folkSelectorTemplate'),
		appliedFilters: $('#appliedFilters')
	},

	wrappers: {
		summary: $('.js-dynamic-search-replace-summary'),
		results: $('.js-dynamic-search-replace-results'),
		pager: $('.js-dynamic-search-replace-pager'),
		block: $('.js-dynamic-search-replace-block'),
		holiday: $('.js-dynamic-search-replace-holiday'),
		collection: $('.js-dynamic-search-replace-collection'),
		facilities: $('.js-dynamic-search-replace-facilities'),
		accomodation: $('.js-dynamic-search-replace-accomodation'),
		appliedFilters: $('.js-dynamic-search-replace-applied-filters')
	},

	set: function() {
		templates.elements.block = templates.elements.block.html();
		templates.elements.folk = templates.elements.folk.html();
		templates.elements.appliedFilters = templates.elements.appliedFilters.html();
	},

	check: function() {

		var pass = true;
		$.each(templates.elements, function(key, value) { if (!value.length) pass = false; });

		$.each(templates.wrappers, function(key, value) {

			// accomodation not always present, therefore shouldn't prevent pass
			if (value.selector === '.js-dynamic-search-replace-accomodation') return;
			//if (!value.length) pass = false;

		});
		return pass;
	}
}

// set base url / create url for request / update browser url
const urlCreate = function(e) {
	const base = window.location.pathname + '?';
	var target = e ? $(e.target) : false,
		url = base + elements.form.serialize();
	if (target && target.attr('href')) url = target.attr('href');

	if ($('.c-mobile-search-nav__button--filter').hasClass('is-active')) {
		url = urlManagementUpdateParam(url, 'filtersActive', 'true');
	}

	return url;
}

const urlUpdate = function(url) {
	history.replaceState(null, '', urlManagementRemoveParam(url, 'filtersActive'));
}

// scroll to results
const scroll = function(mobileFiltersVisible) {

	if (templates.wrappers.block.offset().top === window.site.doc.scrollTop()) return;
	if (mobileFiltersVisible) {
		$('html, body').animate({ scrollTop: window.site.body.offset().top }, 500);
		return;
	}
	$('html, body').animate({ scrollTop: templates.wrappers.block.offset().top }, 500);

}

// submit handler
const submit = (e) => {
	// set and kill wait time for non form elements
	var _waitTime = waitTime;
	var alwaysScroll = false;
	if ($(e.target).attr('data-search-results-scroll')) alwaysScroll = true;
	if (!e || $(e.target).attr('href')) _waitTime = 0;

	if (timeout) clearTimeout(timeout);

	timeout = setTimeout(function() {

		// store filter limit states

		states.filterLimits.store();

		// store tab state

		states.tabs.store();

		// create url

		var url = urlCreate(e);

		// abort previous request
		if (request !== null) {

			request.abort();
			request = null;

		}

		// show loader

		loader();

		// make request

		request = $.ajax({
			url: url,
			dataType: 'html',
			success: function(response) {
				searchBarUpdateSearchBarOptions();
				render(response, url)
			},
			error: function(e) {
				if (e && e.statusText && e.statusText === "abort") {
					return;
				}
			},
			complete: function() {

				// hide loader / clear request / scroll

				let selectedDestination = $('.js-destination-picker-input')[2].value;
				let translatedDestination = $("[data-destination-value = '" + selectedDestination + "']").first().text().trim();
				$('.js-destination-picker-facade').val(translatedDestination);
				loader(true);
				request = null;

				var mobileFiltersHidden = window.site.win.width() > mobileSearchFiltersHideBreakpoint;

				if (alwaysScroll) {
					scroll(!mobileFiltersHidden);
				}

				window.site.body.trigger({ type: "datePickerReInitAfterSearch" });

			}
		});

		timeout = null;

	}, _waitTime);

	return false;

}

// render response from server
const render = function(response, url) {

	var $response = $(response);

	var searchResultsListId = '#search-list-tab';
	var searchResultsFiltersId = '#search-results-filters';
	var searchResultsSummaryClass = '.js-dynamic-search-replace-summary';

	var $searchResultsList = $response.find(searchResultsListId);
	var $searchResultsFilters = $response.find(searchResultsFiltersId);
	var $summary = $response.find(searchResultsSummaryClass);

	$(searchResultsListId).empty().html($searchResultsList.html())
	$(searchResultsFiltersId).empty().html($searchResultsFilters.html())
	$(searchResultsSummaryClass).empty().html($summary.first().html());

	// re initialise datepickers
	formsInit();
	urlUpdate(url);

	// restore filter limit states

	states.filterLimits.restore();

	appliedFiltersInit();
	init(false);
	folksSelectorInit(false);
	searchBlockPopupButtonsInit();
	accommodationOnlyAttachEvents();
	airportPickerReInitAfterSearch();
	helpPinInit();
	toggleBlockInit();

}

const init = function() {
	// on first run only, check for elements and set templates
	if (firstRun) {

		if (!elements.form.length || !templates.check()) return;

		templates.set();
		firstRun = false;
	}
	// cache selectors

	elements.cache();

	// assign / re-assign events
	elements.checks.add(elements.sort).off('change').on('change', submit);
	elements.radios.add(elements.sort).off('change').on('change', submit);
	elements.clear.add(elements.prev).add(elements.next).add(elements.page).on('click', submit);

	attachCheckboxFilterChange();
	accommodationOnlyAttachEvents();
	getMapResults();
}

function getMapResults() {
	var url = '/api/map/search?' + elements.form.serialize();
	var presetSearchParameters = $('.js-search-map-container').attr('data-map-node-preset-querystring');
	if (presetSearchParameters !== "") {
		url += "&" + presetSearchParameters;
	}

	return $.ajax({
		url: url,
		dataType: 'json',
		success: function(response) {
			document.getElementsByClassName('js-search-map-results').item(0).textContent = JSON.stringify(response);

			if (currentTabSelection === 'search-map-tab') {
				$("body").trigger({
					type: "resultsUpdate",
					tabTarget: "#search-map-tab"
				})
			}
		},
		error: function(e) {
			if (e && e.statusText && e.statusText === "abort") {
				return;
			}
			alert('Sorry! Something went wrong - please try again.');
		}
	});
}

export default init;
export { submit, getMapResults }
